exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-bonmarche-js": () => import("./../../../src/pages/bonmarche.js" /* webpackChunkName: "component---src-pages-bonmarche-js" */),
  "component---src-pages-energy-book-js": () => import("./../../../src/pages/energy-book.js" /* webpackChunkName: "component---src-pages-energy-book-js" */),
  "component---src-pages-intermediate-js": () => import("./../../../src/pages/intermediate.js" /* webpackChunkName: "component---src-pages-intermediate-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-author-page-js": () => import("./../../../src/templates/authorPage.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-ebook-landing-js": () => import("./../../../src/templates/ebookLanding.js" /* webpackChunkName: "component---src-templates-ebook-landing-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-pages-js": () => import("./../../../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-sub-category-js": () => import("./../../../src/templates/subCategory.js" /* webpackChunkName: "component---src-templates-sub-category-js" */),
  "component---src-templates-video-article-js": () => import("./../../../src/templates/videoArticle.js" /* webpackChunkName: "component---src-templates-video-article-js" */),
  "component---src-templates-video-category-js": () => import("./../../../src/templates/videoCategory.js" /* webpackChunkName: "component---src-templates-video-category-js" */)
}

